.main-content {
  position: relative;

  // Navbar
  .navbar-top {
    position: fixed;
    left: 60px;
    top: 0;
    right: 0;
    z-index: 100;
    background-color: transparent;
    padding-left: 0 !important;
    padding-right: 0 !important;
    box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
  }

  // Container
  .container-fluid {
    @include media-breakpoint-up(md) {
      padding-left: ($main-content-padding-x + $grid-gutter-width / 2) !important;
      padding-right: ($main-content-padding-x + $grid-gutter-width / 2) !important;
    }
  }
}

// Offsets the main content depending on the sidebar positioning

.navbar-vertical.navbar-expand {
  @each $breakpoint, $dimension in $grid-breakpoints {
    &-#{$breakpoint} {
      @include media-breakpoint-up(#{$breakpoint}) {
        // Left
        &.fixed-left + .main-content {
          margin-left: 60px;
        } // Right
        &.fixed-right + .main-content {
          margin-right: $navbar-vertical-width;
        }
      }
    }
  }
}

.full-background {
  height: 100vh;
  background-color: #fff;
}

button {
  &.floating-button {
    position: absolute;
    left: -25px;
    padding: 1px 6px;
    width: 28px;
    margin: 0;
    display: none;
  }

  &.insert-row {
    top: -11px;
  }

  &.delete-row {
    top: 13px;
  }
}

table {
  &.editing.curve-points {
    td {
      position: relative;

      &:first-child {
        border-left: 50px solid #f6f9fc;
      }

      input {
        min-width: 125px;
      }
    }

    tr {
      &:hover {
        button.floating-button {
          display: block;
        }
      }
    }
  }
}

/* Spinner */

.lds-dual-ring {
  display: inline-block;
  width: 64px;
  height: 64px;

  &:after {
    content: " ";
    display: block;
    width: 46px;
    height: 46px;
    margin: 1px;
    border-radius: 50%;
    border: 5px solid #fff;
    border-color: #dc3545 transparent #dc3545 transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.custom-switch {
  padding-top: calc(0.625rem + 1px);
  padding-bottom: calc(0.625rem + 1px);
}

.no-focus:focus {
  box-shadow: none !important;
}

.notification {
  color: #232e3c;
  text-decoration: none;
  display: flex;
  font-size: 0.83333rem;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;

  &:not(.notification-flush) {
    border: 1px solid #f9fafd;
    background-color: #fff;
    -webkit-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
    border-radius: 0.375rem;
    padding: 1rem;
  }

  &:focus,
  &:hover {
    color: #0b1727;
    text-decoration: none;
  }
}

.notification-body {
  margin-bottom: 0.25rem;
}

.notification-time {
  margin-bottom: 0;
  color: #748194;
  font-size: 0.83333rem;
}

.notification-indicator {
  position: relative;

  &::before {
    position: absolute;
    content: "";
    right: 0.125rem;
    top: 0.5rem;
    height: 1rem;
    width: 1rem;
    border-radius: 50%;
    border: 2px solid #edf2f9;
  }
}

.notification-indicator-number {
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: absolute;
  right: 0.125rem;
  top: 0.505rem;
  height: 1rem;
  width: 1rem;
  font-size: 0.67rem;
  color: #fff;
  font-weight: 700;
}

.notification-indicator-primary::before {
  background-color: #2c7be5;
}

.notification-indicator-warning::before {
  background-color: #f5803e;
}

.notification-indicator-fill::before {
  border: 0;
}

.was-validated .form-control:invalid,
.form-control.is-invalid,
.was-validated .form-control:valid,
.form-control.is-valid {
  background-size: calc(0.55em + 0.425rem) calc(0.55em + 0.425rem);
  background-position: right calc(0.575em + 0.5125rem) center;
  padding-right: 1rem;
}

.blur {
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.8) !important;
}

.selected-series {
  position: sticky;
  top: 85px;
  z-index: 10;
}

.product-progress {
  position: sticky;
  top: 142px;
}

.product-wizard-tabs {
  flex-direction: row;
  position: sticky;
  top: 132px;
  z-index: 10;
}

.product-creation-wizard .product-progress {
  top: 202px;
}

input.modified {
  background: #ffeb3b;
}

.selectable-template {
  cursor: pointer;
  border-radius: 10px;
  padding: 10px;
}

.selectable-template:hover {
  background-color: #fff;
}

input[type="radio"],
input[type="checkbox"] {
  accent-color: #dc3545;
}
