.section-break {
  display: flex;
  flex-basis: 100%;
  align-items: center;
  color: rgba(0, 0, 0, 0.35);
  margin: 8px 0px;
}
.section-break:before,
.section-break:after {
  content: "";
  flex-grow: 1;
  background: rgba(0, 0, 0, 0.05);
  height: 1px;
  font-size: 0px;
  line-height: 0px;
  margin: 0px 8px;
}

.card-header:hover .fas {
  color: #6e6e6e;
}

.btn {
  border-radius: 100px;
  border-width: 1px;
  padding-top: 0px;
  padding-bottom: 0px;
  height: 36px;
  font-size: 16px;
  font-weight: normal;
}

a.btn {
  display: inline-flex;
  align-items: center;
}

.btn-outline-primary{
  border-color:#EA2C13;
}

.input-group:not(:disabled):not(.disabled):hover .input-group-text:not(.disabled-border), 
.input-group:not(.disabled-border):hover input, 
.input-group input:not(:disabled):not(.disabled):hover, 
.input-group-alternative:hover 
input:not(:disabled):not([readonly]):hover:focus, 
input:not(:disabled):not([readonly]):hover,
textarea:not(:disabled):not([readonly]):hover:focus, textarea:not(:disabled):not([readonly]):hover,
select:not(:disabled):not([readonly]):hover
{
  border-color: #e51a28;
}

.btn-secondary.disabled, .btn-secondary:disabled{
  border-color:inherit;
}

.btn-outline-danger:hover, .btn-outline-primary:hover,.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle,
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle
{
  color: #EA2C13;
  background-color: #F8B5AC;
}
.btn-outline-dark:hover,
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle
{
  color:#000;
  background-color: #F2F2F2;
}

.login-background
{
background-image: url(../img//login-background.jpg);
background-size: cover;
}