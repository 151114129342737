/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Core

@import "custom/functions";
@import "custom/variables";
@import "custom/mixins";

// Bootstrap (4.1.3) components

@import "bootstrap/scss/_root";
@import "bootstrap/scss/_reboot";
@import "bootstrap/scss/_type";
@import "bootstrap/scss/_images";
@import "bootstrap/scss/_code";
@import "bootstrap/scss/_grid";
@import "bootstrap/scss/_tables";
@import "bootstrap/scss/_forms";
@import "bootstrap/scss/_buttons";
@import "bootstrap/scss/_transitions";
@import "bootstrap/scss/_dropdown";
@import "bootstrap/scss/_button-group";
@import "bootstrap/scss/_input-group";
@import "bootstrap/scss/_custom-forms";
@import "bootstrap/scss/_nav";
@import "bootstrap/scss/_navbar";
@import "bootstrap/scss/_card";
@import "bootstrap/scss/_breadcrumb";
@import "bootstrap/scss/_pagination";
@import "bootstrap/scss/_badge";
@import "bootstrap/scss/_jumbotron";
@import "bootstrap/scss/_alert";
@import "bootstrap/scss/_progress";
@import "bootstrap/scss/_media";
@import "bootstrap/scss/_list-group";
@import "bootstrap/scss/_close";
@import "bootstrap/scss/_modal";
@import "bootstrap/scss/_tooltip";
@import "bootstrap/scss/_popover";
@import "bootstrap/scss/_carousel";
@import "bootstrap/scss/_utilities";
@import "bootstrap/scss/_print";
@import "bootstrap/scss/_spinners";

// Argon utilities and components

@import "custom/reboot";
@import "custom/utilities";
@import "custom/components";

// Vendor (Plugins)

@import "custom/vendors";

// React differences
@import "react/react-differences";

// App-specific styles
@import "app";