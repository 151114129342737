iframe {
  border: 0;
}

figcaption,
figure,
main {
  display: block;
}

main {
  overflow: hidden;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background: theme-color("light");
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: theme-color("danger");
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: darken(theme-color("danger"), 10%);
}

.form-label-group {
  position: relative;
  input,
  select {
    height: 3.125rem;
    padding: 0.75rem;
    &::-webkit-input-placeholder {
      color: transparent;
    }
    &::-moz-placeholder {
      color: transparent;
    }
    &:-ms-input-placeholder {
      color: transparent;
    }
    &::-ms-input-placeholder {
      color: transparent;
    }
    &::placeholder {
      color: transparent;
    }
    &:not(:-moz-placeholder-shown) {
      padding-top: 1.25rem;
      padding-bottom: 0.25rem;
      ~ {
        label {
          padding-top: 0.25rem;
          padding-bottom: 0.25rem;
          font-size: 12px;
          color: #777;
        }
      }
    }
    &:not(:-ms-input-placeholder) {
      padding-top: 1.25rem;
      padding-bottom: 0.25rem;
      ~ {
        label {
          padding-top: 0.25rem;
          padding-bottom: 0.25rem;
          font-size: 12px;
          color: #777;
        }
      }
    }
    &:not(:placeholder-shown) {
      padding-top: 1.25rem;
      padding-bottom: 0.25rem;
      ~ {
        label {
          padding-top: 0.25rem;
          padding-bottom: 0.25rem;
          font-size: 12px;
          color: #777;
        }
      }
    }
    &:-webkit-autofill {
      ~ {
        label {
          padding-top: 0.25rem;
          padding-bottom: 0.25rem;
          font-size: 12px;
          color: #777;
        }
      }
    }
  }
  label {
    height: 3.125rem;
    padding: 0.75rem;
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    margin-bottom: 0;
    line-height: 1.5;
    color: #495057;
    pointer-events: none;
    cursor: text;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    transition: all 0.1s ease-in-out;
  }
}

.button-pointer {
  height: 15rem !important;
  transform: scaleX(1) rotate(266deg) !important;
  position: absolute;
  margin-left: 9rem;
  margin-top: -4rem;
  opacity: 0.3;
}

.plaque-icon {
  border: 2px dashed white;
  //border-radius: 10px;
}
.plaque-icon:hover,
.plaque-icon-empty {
  border: 2px dashed;
  //border-radius: 10px;
}

.plaque-barcode img {
  width: 100%;
  bottom: 0;
  position: absolute;
}

.squared-right {
  border-radius: 0.375rem 0 0 0.375rem;
}

.squared-left {
  border-radius: 0 0.375rem 0.375rem 0;
}
