//
// Cards
//

@import "../core/cards/card";
@import "../core/cards/card-profile";
@import "../core/cards/card-blockquote";
@import "../core/cards/card-animations";
@import "../core/cards/card-stats";

.card {
  box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
}

.card-header {
  font-weight: 800;
  font-size: 1.25rem;
}
