//
// Nav
//

@import "../core/navs/nav";
@import "../core/navs/nav-pills";

.nav-tabs {
  overflow-x: auto;
  overflow-y: hidden;
  flex-wrap: nowrap;
  white-space: nowrap;
  scroll-behavior: smooth;
  padding-bottom: 2px;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #dc3545;
  background-color: transparent;
  border-color: transparent;
}
