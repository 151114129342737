::-ms-reveal {
    display: none;
}

.collapse-header {
    cursor: pointer;
}

.collapse-header:hover {
    filter: brightness(120%);
}