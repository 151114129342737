//
// Custom form
//

@import "../core/custom-forms/custom-form";
@import "../core/custom-forms/custom-control";
@import "../core/custom-forms/custom-checkbox";
@import "../core/custom-forms/custom-radio";
@import "../core/custom-forms/custom-toggle";

.custom-switch .custom-control-label::before {
  left: -2.9375rem;
}

.custom-switch .custom-control-label::after {
  left: calc(-2.9375rem + 2px);
}

.custom-switch {
  padding-left: 2.9375rem;
}
